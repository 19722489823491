
class cache {
    putItem = (key, value) => {
        window.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
    }
    getItem = (key) => {
        return window.localStorage.getItem(key)
    }
    removeItem = (key) => {
        window.localStorage.removeItem(key)
    }
    clearItem = () => {
        window.localStorage.clear()
    }
}

export default new cache()


//

