import VueRouter from "vue-router";
import cache from "@/utils/cache";

const routes = [
    {
        name: '布局', path: '/', redirect: {path: '/home'}, component: () => import('@/views/layout/Layout'),
        children: [
            {name: '首页', path: '/home', component: () => import('@/views/home')},
            {name: '分类管理', path: '/category', component: () => import('@/views/pms/category/category')},
            {name: '规格管理', path: '/spec', component: () => import('@/views/pms/spec/spec')},
            {name: '商品管理', path: '/goods', component: () => import('@/views/pms/goods/goods')},
            {name: '商品添加', path: '/goods/add', component: () => import('@/views/pms/goods/goodsAdd')},
            {name: '商品修改', path: '/goods/update', component: () => import('@/views/pms/goods/goodsUpdate')},
            {name: '商品规格管理', path: '/goods/spec', component: () => import('@/views/pms/goodsSpec/goodsSpec')},
            {name: '规格添加', path: '/goods/spec/add', component: () => import('@/views/pms/goodsSpec/goodsSpecAdd')},
            {name: '规格修改', path: '/goods/spec/update', component: () => import('@/views/pms/goodsSpec/goodsSpecUpdate')},
            {name: '优惠券', path: '/coupon', component: () => import('@/views/pms/coupon/coupon')},
            {name: '商户配置', path: '/merchant', component: () => import('@/views/mms/merchant')},

        ]
    },
    {name: '登录', path: '/login', component: () => import('@/views/ums/login')},

]
const router = new VueRouter({
    mode: 'history',
    routes
})

//全局守卫
router.beforeEach((to, from, next) => {
    if (cache.getItem('auth-token')) {
        next()
    } else {
        to.fullPath === '/login' ? next() : next({path: '/login'})
    }
})
export default router
