import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router/router";
import '@/assets/css/global.css'
import time_global from "@/utils/time_global";

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI);

//自定义函数
Vue.prototype.toDate = time_global.toDate
Vue.prototype.toTime = time_global.toTime
Vue.prototype.toDateTime = time_global.toDateTime
Vue.prototype.toTimestamp = time_global.toTimestamp

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')
