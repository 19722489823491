import moment from "moment";

const formatted = (time, format) => {
    if (time === undefined || time === null || time === '') {
        return null
    }
    return moment(time).format(format);
}


export default {
    //格式化时间
    toDateTime(time) {
        if (time === undefined || time === null || time === '') {
            return null
        }
        return moment(time).format('yyyy-MM-DD HH:mm:ss')
    },
    toDate(time) {
        if (time === undefined || time === null || time === '') {
            return null
        }
        return moment(time).format('yyyy-MM-DD')
    },

    toTime(time) {
        if (time === undefined || time === null || time === '') {
            return null
        }
        return moment(time).format('HH:mm:ss')
    },
    toTimestamp(time) {
        if (time === undefined || time === null || time === '') {
            return null
        }
        return moment(time).valueOf()
    },
    formatted(time, format) {
      formatted(time,format)
    }
}
